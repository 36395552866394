<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'data' })" />
        </div>
      </div>
      <div class="shdow">
        

        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h2 text-secondary">Modifier Concept</p>
         <b-button
            variant="secondary"
            >Confirmer</b-button
          >
          <hr class="w-100" />
        </div>

        
        <div class="row">
          <!-- First Col -->
          <div class="col-12">
            <b-form-group
              label-cols="12"
              label="Concept Métier"
              label-class="font-weight-bold"
            >
              <b-form-input type="text"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea rows="6" placeholder="Description"></b-form-textarea>
            </b-form-group>

          </div>
        </div>
      </div>

      
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
export default {
  components: {
    ModalActions,
  },
  data: () => ({
    editMode: false,
    
  }),
  methods: {
    
  },
};
</script>

<style></style>
